body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.maxw {
  @apply max-w-7xl;
}

.center {
  @apply mx-auto;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.bebas {
  font-family: 'Bebas Neue', sans-serif;
}

.hind {
  font-family: 'Hind Madurai', sans-serif;
}

.input-description{
  display: none;
}

.grecaptcha-badge {
    visibility: hidden !important;
}